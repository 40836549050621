import React from 'react'
import { Link } from "react-router-dom";
import './Header.css'

class Header extends React.Component {

  render() {
    return (
      <div className="Header">
        <h6 className="Header-Title"><Link to="/">dobiq</Link></h6>
      </div>
    )
  }

}

export default Header