import React from 'react'
import ImageGallery from 'react-image-gallery';
import './Phone.css'

const slideImages = [
  { original: 'img/screenshot_todo_1.png' },
  { original: 'img/screenshot_todo_2.png' },
  { original: 'img/screenshot_todo_3.png' },
  { original: 'img/screenshot_todo_4.png' },
  { original: 'img/screenshot_todo_5.png' },
  { original: 'img/screenshot_todo_6.png' }
];

class Phone extends React.Component {

  render() {
    return (
      <div className="Phone">
        <div className="Phone-Bazel"></div>
        <div className="Phone-Screen">
          <ImageGallery items={slideImages} showNav={false} showThumbnails={false} autoPlay={true} showPlayButton={false} showFullscreenButton={false} disableSwipe={true} />
        </div>
      </div>
    )
  }

}

export default Phone