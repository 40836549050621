import React from 'react'
import ReactMarkdown from 'react-markdown';
import './Terms.css'

class Terms extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      termsText: ""
    }
  }

  componentDidMount() {
    const readmePath = require("../doc/TermsAndConditions.md");
  
    fetch(readmePath)
      .then(response => response.text())
      .then(text => this.setState({ termsText: text }))
  }

  render() {
    return (
      <div className="Terms markdown">
        <ReactMarkdown source={this.state.termsText} />
      </div>
    )
  }

}

export default Terms