import React from 'react'
import './WorkspaceShare.css'
import query from 'query-string'
import Helmet from 'react-helmet'
//import playlogo from '.../img/img_google_play_badge_230.png'


class WorkspaceShare extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      workspaceName: "",
      icon:"",
      workspaceShareString:"",
    }
  }

  componentDidMount() {
    const vala=query.parse(decodeURIComponent(this.props.location.search));
    
     this.setState({ workspaceName: vala.n });
     this.setState({ icon: '0x'+parseInt(vala.i).toString(16) });
     this.setState({workspaceShareString: `'${vala.n}' is shared with you!`});
  }

  render() {
    //document.title = this.state.workspaceName;
    return (
      <div className="WorkspaceShare">
                <h1>to-do</h1>
        Simple. Free. Fast.<br />
        
        <br />
        <span
      className="emoji"
      role="img">
        {String.fromCodePoint(this.state.icon)}
        </span>
        {this.state.workspaceShareString}<br />
        <br />
        <a href="https://play.google.com/store/apps/details?id=com.dobiq.todo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img alt="Get it on Google Play" src="../img/img_google_play_badge_230.png" />
        </a>
        <Helmet>
          {/*<title>{this.state.workspaceName}</title> */}
          <meta property="og:description" content={this.state.workspaceShareString} data-react-helmet="true"/>
        </Helmet>
      </div>
    )
  }
}

export default WorkspaceShare