import React from 'react'
import { Route, Switch } from 'react-router-dom';
import Header from './header'
import ShowCase from './showcase'
import Privacy from './privacy'
import PrivacyNotepad from './privacyNotepad'
import Terms from './terms'
import ThirdParty from './thirdParty';
import WorkspaceShare from './workspaceShare';
import Contact from './contact'
import Footer from './footer'
import './App.css'

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Switch>
          <Route path="/" component={ShowCase} exact />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/privacy-notepad" component={PrivacyNotepad} />
          <Route path="/third-party" component={ThirdParty} />
          <Route path="/links/workspace-share" component={WorkspaceShare} />
          <Route path="/workspace-share" component={WorkspaceShare} />
        </Switch>
        </main>
      <Contact />
      <Footer />
    </div>
  );
}

export default App
