import React from 'react'
import './Contact.css'

class Contact extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      submitStatus: ""
    };
  }

  submitContactForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();

    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      } else if (xhr.status === 200) {
        form.reset();
        this.setState({ submitStatus: "SUCCESS" });
      } else {
        this.setState({ submitStatus: "ERROR" });
      }
    };

    xhr.send(data);
  }

  render() {
    return (
      <div className="Contact">
        <h5><b>BE IN</b></h5>
        <h4><b>TOUCH</b></h4>
        Please fill the form below to reach us
        <form onSubmit={this.submitForm} action="https://formspree.io/f/xdopkbgy" method="POST">
          <input type="email" name="email" placeholder="Email" required />
          <br />
          <input type="text" name="message" placeholder="Message" required />
          <br />
          {this.state.submitStatus === "SUCCESS" ? <p>Thanks!</p> : <button>Send *</button>}
          {this.state.submitStatus === "ERROR" && <p>Ooops! There was an error.</p>}
          <div className="Contact-Disclaimer">
            <p>
              * This contact form is provided and operated by a third party partner, <a href="https://formspree.io" target="_blank" rel="noopener noreferrer" >Formspree</a>. 
              Please see their <a href="https://formspree.io/legal/terms-of-service" target="_blank" rel="noopener noreferrer" >Terms of Service</a> and <a href="https://formspree.io/legal/privacy-policy" target="_blank" rel="noopener noreferrer" >Privacy Policy</a>. 
              If you are not comfortable with using this service, please reach us out via email: <a href="mailto: todoapp@dobiq.com">todoapp@dobiq.com</a>.
            </p>
            <p>
              We, dobiq, will not share your email address with any other third parties, and we will not send you any advertising related emails. 
              Your email address will only be used to contact you regarding your message, if required.
            </p>
          </div>
      </form>
      </div>
    )
  }

}

export default Contact
