import React from 'react'
import './IconStamp.css'

class IconStamp extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
          opactiy: (Math.floor(Math.random() * 6) + 4) / 10
        };
      }

    render() {
        return (
            <i className={`IconStamp fas ${this.props.iconName}`} style={{ top: this.props.top, transform: `translateX(${this.props.left})`, opacity: this.state.opactiy }}></i>
        )
    }

}

export default IconStamp