import React from 'react'
import { Link } from "react-router-dom";
import './Footer.css'

class Footer extends React.Component {

  render() {
    return (
      <div className="Footer">
        <div className="Footer-Contact">
          <Link to="/terms">Terms and Conditions</Link> | <Link to="/privacy">Privacy Policy</Link><br />
          <a href="mailto: todoapp@dobiq.com">todoapp@dobiq.com</a> | Berlin <img alt="lgbt friendly" src="../img/img_love_24.png" /> &amp; College Park, MD
        </div>
      </div>
    )
  }

}

export default Footer