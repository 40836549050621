const ArrayUtils = {

  shuffleArray: function(array) {
    let i = array.length - 1;
  
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      
      array[i] = array[j];
      array[j] = temp;
    }
  
    return array;
  }

}

export default ArrayUtils