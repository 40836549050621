import React from 'react'
import ReactMarkdown from 'react-markdown';
import './PrivacyNotepad.css'

class PrivacyNotepad extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      privacyPolicyText: ""
    }
  }

  componentDidMount() {
    const readmePath = require("../doc/PrivacyPolicy-notepad.md");
  
    fetch(readmePath)
      .then(response => response.text())
      .then(text => this.setState({ privacyPolicyText: text }))
  }

  render() {
    return (
      <div className="Privacy markdown">
        <ReactMarkdown source={this.state.privacyPolicyText} />
      </div>
    )
  }

}

export default PrivacyNotepad