import React from 'react'
import ReactMarkdown from 'react-markdown';
import './ThirdParty.css'

class ThirdParty extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      thirdPartyText: ""
    }
  }

  componentDidMount() {
    const readmePath = require("../doc/ThirdParty.md");
  
    fetch(readmePath)
      .then(response => response.text())
      .then(text => this.setState({ thirdPartyText: text }))
  }

  render() {
    return (
      <div className="ThirdParty markdown">
        <ReactMarkdown source={this.state.thirdPartyText} />
      </div>
    )
  }

}

export default ThirdParty