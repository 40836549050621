import React from 'react'
import Phone from './Phone'
import IconStamp from './IconStamp'
import ArrayUtils from '../utils/ArrayUtils'
import './ShowCase.css'

const iconNames = [
  "fa-ghost",
  "fa-heart",
  "fa-envelope",
  "fa-bell",
  "fa-comment-dots",
  "fa-map-marker-alt",
  "fa-star",
  "fa-calendar-alt",
  "fa-paw",
  "fa-share-alt",
  "fa-search",
  "fa-wallet",
  "fa-grin-stars",
  "fa-pen-fancy",
  "fa-bus"
]

const iconPositions = [
  { top: "420px", left: "-240px" },
  { top: "456px", left: "-390px" },
  { top: "565px", left: "-285px" },
  { top: "620px", left: "-460px" },
  { top: "700px", left: "-230px" },
  { top: "760px", left: "-410px" },
  { top: "870px", left: "-300px" },
  { top: "410px", left: "240px" },
  { top: "460px", left: "370px" },
  { top: "560px", left: "230px" },
  { top: "590px", left: "470px" },
  { top: "680px", left: "400px" },
  { top: "720px", left: "280px" },
  { top: "840px", left: "410px" },
  { top: "885px", left: "230px" }
]

class ShowCase extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      shuffledIconNames: ArrayUtils.shuffleArray(iconNames)
    };
  }

  render() {
    return (
      <div className="ShowCase">
        {iconPositions.map((value, index) => { return <IconStamp key={this.state.shuffledIconNames[index]} iconName={this.state.shuffledIconNames[index]} top={value.top} left={value.left} /> })}
        <h1>to-do</h1>
        Simple. Free. Fast.<br />
        The only app you need to organize your day.<br />
        <br />
        <a href="https://play.google.com/store/apps/details?id=com.dobiq.todo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img alt="Get it on Google Play" src="./img/img_google_play_badge_230.png" />
        </a>
        <Phone />
      </div>
    )
  }

}

export default ShowCase